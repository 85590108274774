import React from "react";
import { graphql } from "gatsby";

import Layout from "../../components/Layout";
import SEO from "../../components/seo";
import Header from "../../components/Header";
import Breadcrumb from "../../components/Breadcrumb";

import ArticleContainer from "../../style/Article/ArticleContainer";
import { Heading } from "../../style/Heading";
import ArticleStyle from "../../style/Article";
import { Container, Flex, Wrapper } from "../../style/Grid";

import { htmlDecode } from "../../utils";

const SimplePageTemplate = ({
  uri,
  data: {
    wp: {
      pageBy: {
        slug,
        title,
        pageContent: { content },
        seo: { metaDesc },
      },
    },
  },
}) => {
  const breadcrumbs = [
    {
      label: htmlDecode(title),
      link: uri,
    },
  ];

  const containsPDFsToDownload = slug.includes("povinne");

  const pageContent = content
    .replace(/https:\/\/admin.kapitol.cz\//g, "https://kapitol.cz/")
    .replace(/https:\/\/tadmin.kapitol.cz\//g, "https://test.kapitol.cz/");

  return (
    <Layout>
      <SEO title={title} description={metaDesc} />
      <Header variant="light" />

      <Wrapper>
        <Container flexDirection="column">
          <Flex flexDirection="column">
            <Breadcrumb breadcrumbs={breadcrumbs} />
            <Heading as={"h1"} variant={"h1"} uppercase>
              {htmlDecode(title)}
            </Heading>
          </Flex>
        </Container>
      </Wrapper>

      <Wrapper>
        <Container flexDirection="column">
          <ArticleContainer>
            <ArticleStyle
              simplePage={true}
              attachments={containsPDFsToDownload}
              dangerouslySetInnerHTML={{ __html: pageContent }}
            />
          </ArticleContainer>
        </Container>
      </Wrapper>
    </Layout>
  );
};

export default SimplePageTemplate;

export const pageQuery = graphql`
  query SIMPLE_PAGE_QUERY($uri: String) {
    wp {
      pageBy(uri: $uri) {
        title
        slug
        pageContent {
          template
          content
        }
        seo {
          metaDesc
        }
      }
    }
  }
`;
